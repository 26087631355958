import React, { useEffect } from "react";
import "./AboutUs.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="AboutUs">
      <div className="AboutUs__banner">
        <div className="AboutUs__first__banner">
          <div className="AboutUs__first__banner__filter"></div>
          <div className="AboutUs__first__banner__inside" data-aos="fade-right">
            <p className="with25">
              <FormattedMessage
                id="AboutUs__1"
                defaultMessage={"meet buildkos"}
              />
            </p>

            <p className="with80">
              <FormattedMessage
                id="AboutUs__2"
                defaultMessage={"From blueprints to buildings"}
              />
            </p>
          </div>
        </div>

        <div className="AboutUs__second__banner">
          <div className="AboutTexts" data-aos="fade-right">
            <p className="with62">
              <FormattedMessage id="AboutUs__3" defaultMessage={"About us"} />
            </p>

            <p className="with18">
              <FormattedMessage
                id="AboutUs__4"
                defaultMessage={
                  "BuildKos began as a small family-owned business with a vision to transform local landscapes through quality construction and sustainable practices. Over the years, we've expanded our expertise and portfolio, completing over 200 projects across commercial, residential, and industrial sectors."
                }
              />
            </p>
          </div>

          <div className="AboutUs__second__banner__right" data-aos="fade-left">
            <div className="onlyLogo"></div>
            <p className="with35">
              <FormattedMessage
                id="AboutUs__5"
                defaultMessage={"A successful  journey"}
              />
            </p>
            <p className="with20">
              <FormattedMessage
                id="AboutUs__6"
                defaultMessage={
                  "Our journey is built on a foundation of perseverance, innovation, and community spirit, propelling us forward as leaders in the construction industry."
                }
              />
            </p>
          </div>
        </div>
      </div>

      <div className="AboutUs__1">
        <div className="left" data-aos="fade-left">
          <p className="with25">
            <FormattedMessage id="AboutUs__7" defaultMessage={"OUR VALUES"} />
          </p>
          <div className="insideLeftAbout">
            <div className="aboutUsCard1">
              <div className="aboutUsCard1__left dcenter">
                <div className="icon iconAbout1"></div>
              </div>

              <div className="right">
                <span>
                  <FormattedMessage
                    id="AboutUs__8"
                    defaultMessage={"Integrity"}
                  />
                </span>

                <p>
                  <FormattedMessage
                    id="AboutUs__9"
                    defaultMessage={
                      "Always honest and clear in everything we do."
                    }
                  />
                </p>
              </div>
            </div>

            <div className="aboutUsCard1">
              <div className="aboutUsCard1__left dcenter">
                <div className="icon iconAbout2"></div>
              </div>

              <div className="right">
                <span>
                  <FormattedMessage
                    id="AboutUs__10"
                    defaultMessage={"Excellence"}
                  />
                </span>

                <p>
                  <FormattedMessage
                    id="AboutUs__11"
                    defaultMessage={
                      "Focused on high-quality work and careful attention to every detail."
                    }
                  />
                </p>
              </div>
            </div>

            <div className="aboutUsCard1">
              <div className="aboutUsCard1__left dcenter">
                <div className="icon iconAbout3"></div>
              </div>

              <div className="right">
                <span>
                  <FormattedMessage
                    id="AboutUs__12"
                    defaultMessage={"Collaboration"}
                  />
                </span>

                <p>
                  <FormattedMessage
                    id="AboutUs__13"
                    defaultMessage={
                      "Working together with clients, suppliers, and team members to achieve the best results."
                    }
                  />
                </p>
              </div>
            </div>

            <div className="aboutUsCard1">
              <div className="aboutUsCard1__left dcenter">
                <div className="icon iconAbout4"></div>
              </div>

              <div className="right">
                <span>
                  <FormattedMessage
                    id="AboutUs__14"
                    defaultMessage={"Sustainability"}
                  />
                </span>

                <p>
                  <FormattedMessage
                    id="AboutUs__15"
                    defaultMessage={
                      "Committed to eco-friendly practices that help our community and the environment for years to come."
                    }
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="rightAbout1" data-aos="flip-up"></div>
      </div>

      <div className="AboutUs__2">
        <div className="left" data-aos="fade-right">
          <div className="aboutTitleAndText">
            <p className="with62">
              <FormattedMessage
                id="AboutUs__16"
                defaultMessage={"What we do"}
              />
            </p>

            <p className="with18">
              <FormattedMessage
                id="AboutUs__17"
                defaultMessage={
                  "BuildKos offers a comprehensive array of construction services, spanning from the early stages of design and planning to the final touches of project completion. Our expertise includes constructing new residential complexes, renovating outdated infrastructure, and enlarging corporate facilities. We oversee every phase of the construction process. By incorporating cutting-edge technology and adopting innovative methodologies, our skilled team guarantees efficiency, safety, and exceptional quality in all our projects."
                }
              />
            </p>

            <Link className="dcenter">
              <FormattedMessage
                id="AboutUs__18"
                defaultMessage={"Our Services"}
              />
            </Link>
          </div>
        </div>
        <div className="right">
          <div className="aboutUsCard2 aboutUsCard2__1" data-aos="fade-left">
            <span className="aboutUsIcon2__1 aboutUsIcon2__1__1"></span>
            <p className="with22">
              <FormattedMessage
                id="AboutUs__19"
                defaultMessage={"Commercial Construction"}
              />
            </p>
          </div>

          <div className="aboutUsCard2 aboutUsCard2__2" data-aos="fade-right">
            <span className="aboutUsIcon2__1 aboutUsIcon2__1__2"></span>
            <p className="with22">
              <FormattedMessage
                id="AboutUs__20"
                defaultMessage={"Residential Construction"}
              />
            </p>
          </div>

          <div className="aboutUsCard2 aboutUsCard2__3" data-aos="fade-left">
            <span className="aboutUsIcon2__1 aboutUsIcon2__1__1"></span>
            <p className="with22">
              <FormattedMessage
                id="AboutUs__19"
                defaultMessage={"Commercial Construction"}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="AboutUs__3">
        <div className="left" data-aos="fade-left">
          <div className="aboutTitleAndText">
            <p className="with62">
              <FormattedMessage
                id="AboutUs__21"
                defaultMessage={"Our commitment"}
              />
            </p>

            <p className="with18">
              <FormattedMessage
                id="AboutUs__22"
                defaultMessage={
                  "Our commitment at BuildKos is to deliver projects that exceed our clients’ expectations. We focus on being a dependable partner to our stakeholders through proactive communication, precise project management, and continuous learning and adaptation to new methods and technologies."
                }
              />
            </p>
          </div>
        </div>
        <div className="right" data-aos="flip-up"></div>
      </div>

      <div className="AboutUs__4">
        <div className="left" data-aos="fade-right">
          <div className="aboutTitleAndText">
            <p className="with62">
              <FormattedMessage
                id="AboutUs__23"
                defaultMessage={"Why choose us?"}
              />
            </p>

            <p className="with18">
              <FormattedMessage
                id="AboutUs__24"
                defaultMessage={
                  "Choosing BuildKos means partnering with a company that stands firmly for quality, reliability, and innovation. Our extensive portfolio showcases our capability in handling diverse construction demands, while our testimonials reflect our commitment to client satisfaction."
                }
              />
            </p>
          </div>

          <p className="with40">
            <FormattedMessage
              id="AboutUs__25"
              defaultMessage={
                "With Buildkos, you invest not just in a building, but in a lasting relationship."
              }
            />
          </p>
        </div>

        <div className="right" data-aos="flip-up"></div>
      </div>
    </div>
  );
};

export default AboutUs;
