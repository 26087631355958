import React, { useState } from "react";
import "./MobileNav.scss";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import ThemeSwitcher from "../../ThemeSwitcher/ThemeSwitcher";
import SelectLanguage from "../../../shared/SelectLanguage/SelectLangugage";
import WhatWeDoMobile from "../DropDown/WhatWeDo/WhatWeDoMobile";
import ProjectsMenuMobile from "../DropDown/Projects/ProjectsMenuMobile";
import { FormattedMessage } from "react-intl";

const MobileNav = (props) => {
  const [open, setOpen] = useState(false);
  const [course, setCourse] = useState(false);
  const [projects, setProjects] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleCourseClick = () => {
    setCourse(!course);
  };

  const handleProjectsClick = () => {
    setProjects(!projects);
  };

  return (
    <div className="MobileNav">
      <div className="handleClick" onClick={handleOpen}>
        {open ? (
          <div id="closeBtn">
            <CloseIcon />
          </div>
        ) : (
          <MenuIcon />
        )}
      </div>

      <div
        className={`bgMobile  ${open ? "openMenuMobile" : "closeMenuMobile"}`}
      >
        <div className="left_mobile">
          <div className="nav_links">
            <div className="nav-link about-link">
              <Link to="/about-us" className="inner-nav-link">
                <FormattedMessage
                  id="MobileNav__1"
                  defaultMessage={"About us"}
                />
              </Link>
            </div>

            <div className="nav-link courses-link">
              <Link
                to=""
                className="inner-nav-link"
                onClick={handleCourseClick}
              >
                <FormattedMessage
                  id="MobileNav__2"
                  defaultMessage={"What we do"}
                />
                <MdKeyboardArrowDown className="arrow1" />
              </Link>
              {course && <WhatWeDoMobile />}
            </div>

            <div className="nav-link blog-link">
              <Link
                to=""
                className="inner-nav-link"
                onClick={handleProjectsClick}
              >
                <FormattedMessage
                  id="MobileNav__3"
                  defaultMessage={"Projects"}
                />

                <MdKeyboardArrowDown className="arrow1" />
              </Link>
              {projects && <ProjectsMenuMobile />}
            </div>

            <div className="nav-link community-link">
              <Link to="" className="inner-nav-link">
                <FormattedMessage id="MobileNav__4" defaultMessage={"News"} />
              </Link>
            </div>

            <div className="nav-link Contact_us-link">
              <Link to="" className="inner-nav-link">
                <FormattedMessage
                  id="MobileNav__5"
                  defaultMessage={"Careers"}
                />
              </Link>
            </div>
          </div>

          <div className="nav_auth">
            <Link to={"/contact-us"} className="apply_now dcenter">
              <FormattedMessage
                id="MobileNav__6"
                defaultMessage={"Contact us"}
              />
            </Link>

            <div className="mobileNavFooter">
              <p>@2024 BuildKos</p>

              <div className="mobileFlex">
                <ThemeSwitcher />
                <SelectLanguage
                  setLanguage={props.setLanguage}
                  language={props.language}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
