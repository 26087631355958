import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import './dropdown.scss'
import Carousel from 'react-elastic-carousel';
import { projectsData } from './projects'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { FormattedMessage } from 'react-intl';


const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: true },
    { width: 550, itemsToShow: 2, itemsToScroll: 1 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 4 },
    { width: 1750, itemsToShow: 4 },
];


const ProjectsMenu = () => {

    const carouselRef = useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo("single-slide".length);
        }
    };


    return (
        <div className='nav-megamenu courses-megamenu projects-megamenu'>
            <div className="top">
                <p>
                <FormattedMessage id="ProjectsMenu__1" defaultMessage={"Our latest projects"} />
                </p>

                <Link className='viewAll'>
                <FormattedMessage id="ProjectsMenu__2" defaultMessage={"View all"} />
                    <IoIosArrowRoundForward />
                </Link>
            </div>

            <Carousel
                className='featured-carousel'
                breakPoints={breakPoints}
                ref={carouselRef}
                onPrevStart={onPrevStart}
                showArrows={true}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
                enableSwipe={true}
            >
  
                {
                    projectsData.map((item) => (
                    <Link
                    to={{
                        pathname: `/projects/${item.id}/${item.title.replace(
                          / /g,
                          "_"
                        )}`,
                        state: { projectsDataTo: item },
                      }}
                    key={item.id} className='projectsCard'>
                        <div className="topImage"
                            style={{
                                content: `url(${item.icon})`
                            }}
                        ></div>
                        <p>
                            {item.title}
                        </p>
                    </Link>
                    ))
                }
            </Carousel>
        </div>
    )
}

export default ProjectsMenu