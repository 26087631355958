import React from 'react'
import './Home.scss'
import HomeBanner from '../../component/Home/HomeBanner/HomeBanner'
import TheFundation from '../../component/Home/TheFundation/TheFundation'
import JustConstruct from '../../component/Home/JustConstruct/JustConstruct'
import OurServices from '../../component/Home/OurServices/OurServices'
import BuildingYourFeature from '../../component/Home/BuildingYourFeature/BuildingYourFeature'
import GetInTouch from '../../component/Home/GetInTouch/GetInTouch'
import OurProjects from '../../component/Home/OurProjects/OurProjects'

const Home = () => {
  return (
    <div className='Home'>
      <HomeBanner />
      <TheFundation />
      <JustConstruct />
      <OurServices />
      <BuildingYourFeature />
      <OurProjects />
      <GetInTouch />

    </div>
  )
}

export default Home