import React from "react";
import { Link } from "react-router-dom";
import { whatWeDoData } from "./what.js";
import "./dropdown.scss";
import { FormattedMessage } from "react-intl";

const WhatWeDo2 = () => {
  return (
    <div className="whatWeDoNav courses-megamenu">
      <div className="menu-links">
        <div className="inner-links">
          {whatWeDoData.map((props) => {
            return (
              <Link key={props.id} to={props.to} className="menu-main-link">
                <div className="main">
                  <div className="icon-container dcenter">
                    <div
                      className="icon"
                      style={{ content: `url(${props.icon})` }}
                    ></div>
                  </div>
                  <div className="block">
                    <h6>
                      {props.title}
                      <div className="arrow45"></div>
                    </h6>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>

      <div className="right">
        <div className="rightImage"></div>
        <p className="with24">
          <FormattedMessage
            id="WhatWeDo2__1"
            defaultMessage={"Do you have a project in mind?"}
          />
          </p>

        <p className="with16">
          <FormattedMessage
            id="WhatWeDo2__2"
            defaultMessage={"Let us build it for you"}
          />
        </p>

        <Link className="dcenter">
          
          <FormattedMessage
            id="WhatWeDo2__3"
            defaultMessage={"Get in touch"}
          />
          </Link>
      </div>
    </div>
  );
};

export default WhatWeDo2;
