import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import SelectLanguage from "../SelectLanguage/SelectLangugage";
import { FormattedMessage } from "react-intl";

const Footer = (props) => {
  return (
    <div className="shared-footer">
      <div className="main-row">
        <div className="first-col">
          <div className="top">
            <div className="footerLogo"></div>
            <p className="yourDream">
              <FormattedMessage
                id="Footer__1"
                defaultMessage={"Your dream partner in construction"}
              />
            </p>
          </div>
          <div className="links1">
            <div className="socialMediaLinks">
              <Link to="https://www.instagram.com/buildkos/">
                {/* <img src={instagram} alt="" /> */}
                <div className="socialMediaIcon instagramIcon"></div>
              </Link>
              <Link to="https://www.facebook.com/buildkos">
                {/* <img src={facebook} alt="" /> */}
                <div className="socialMediaIcon facebookIcon"></div>
              </Link>
              <Link to="https://www.linkedin.com/company/buildkos/">
                {/* <img src={linkedin} alt="" />    */}
                <div className="socialMediaIcon linkedinIcon"></div>
              </Link>

              <Link to="https://www.twitter.com/company/buildkos/">
                {/* <img src={linkedin} alt="" />    */}
                <div className="socialMediaIcon twitterIcon"></div>
              </Link>

              <Link to="https://mediakos.com/buildkos">
                {/* <img src={tiktok} alt="" /> */}
                <div className="socialMediaIcon tiktokIcon"></div>
              </Link>
            </div>
          </div>
        </div>

        <div className="all-footer-links">
          <div className="footerLink">
            <h2>
              <FormattedMessage id="Footer__2" defaultMessage={"company"} />
            </h2>

            <div>
              <Link to="/about-us">
                <FormattedMessage id="Footer__3" defaultMessage={"About us"} />
              </Link>
              <Link to="/">
                <FormattedMessage
                  id="Footer__4"
                  defaultMessage={"Leadership"}
                />
              </Link>
              <Link to="/">
                <FormattedMessage id="Footer__5" defaultMessage={"Careers"} />
              </Link>
              <Link to="/news">
                <FormattedMessage id="Footer__6" defaultMessage={"News"} />
              </Link>
            </div>
          </div>

          <div className="footerLink">
            <h2>
              <FormattedMessage id="Footer__7" defaultMessage={"services"} />
            </h2>

            <div>
              <Link to="/">
                <FormattedMessage
                  id="Footer__8"
                  defaultMessage={"Commercial Construction"}
                />
              </Link>
              <Link to="/">
                <FormattedMessage
                  id="Footer__9"
                  defaultMessage={"Residential Construction"}
                />
              </Link>
              <Link to="/">
                <FormattedMessage
                  id="Footer__10"
                  defaultMessage={"Construction Project Management"}
                />
              </Link>
              <Link to="/">
                <FormattedMessage
                  id="Footer__11"
                  defaultMessage={"Project Planning & Design"}
                />
              </Link>
              <Link to="/">
                <FormattedMessage
                  id="Footer__12"
                  defaultMessage={"Custom Project Constriction"}
                />
              </Link>

              <Link to="/">
                <FormattedMessage
                  id="Footer__13"
                  defaultMessage={"Renovation Projects"}
                />
              </Link>
            </div>
          </div>

          <div className="footerLink">
            <h2>
              <FormattedMessage id="Footer__14" defaultMessage={"projects"} />
            </h2>

            <div>
              <Link to="/">
                <FormattedMessage
                  id="Footer__15"
                  defaultMessage={"All Projects"}
                />
              </Link>
              <Link to="/">
                <FormattedMessage
                  id="Footer__16"
                  defaultMessage={"Prestige Residence"}
                />
              </Link>
              <Link to="/">
                <FormattedMessage
                  id="Footer__17"
                  defaultMessage={"The Green Project"}
                />
              </Link>
              <Link to="/">
                <FormattedMessage
                  id="Footer__18"
                  defaultMessage={"BuildKos Group"}
                />
              </Link>

              <Link to="/">
                <FormattedMessage
                  id="Footer__19"
                  defaultMessage={"Royal Hill"}
                />
              </Link>
            </div>
          </div>

          <div className="footerLink lastFooterLink inRightSideFooter">
            <div className="CallCard">
              <div className="left dcenter">
                <div className="icon icon1"></div>
              </div>

              <div className="right">
                <span>Email</span>

                <p>info@buildkos.com</p>
              </div>
            </div>

            <div className="CallCard">
              <div className="left dcenter">
                <div className="icon"></div>
              </div>

              <div className="right">
                <span>
                  <FormattedMessage id="Footer__20" defaultMessage={"Phone"} />
                </span>

                <p>+ 123 456 789 00</p>
              </div>
            </div>

            <div className="CallCard">
              <div className="left dcenter">
                <div className="icon icon3"></div>
              </div>

              <div className="right">
                <span>
                  <FormattedMessage
                    id="Footer__21"
                    defaultMessage={"Headquarters:"}
                  />
                </span>

                <p>"Ali Kelmendi", No. 10, Prishtina, Kosovo</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="last-row">
        <p>
          © 2023 BuildKos.
          <FormattedMessage
            id="Footer__22"
            defaultMessage={"All rights reserved."}
          />
        </p>

        <div className="lastFooterRight">
          <ThemeSwitcher />
          <SelectLanguage
            setLanguage={props.setLanguage}
            language={props.language}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
