import "./App.scss";
import "./dark.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { IntlProvider } from "react-intl";
import { translate } from './translation/translate';
import { useSelector } from "react-redux";
import Navbar from "./component/shared/Navbar/Navbar";
import Footer from "./component/shared/Footer/Footer";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import Projects from "./pages/Projects/Projects";
import ScrollToTop from "./ScrollToTop";
import ProjectsDetails from "./pages/ProjectsDetails/ProjectsDetails";
import News from "./pages/News/News";


function App(props) {
  const language = useSelector((state) => state.language.language);

  return (
    <div className="App">
      <IntlProvider
        locale={language}
        formats={{ number: "en" }}
        messages={translate[language]}
      >
        <BrowserRouter>
        <ScrollToTop />
        <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id/:nameProject" element={<ProjectsDetails />} />
            <Route path="/news" element={<News />} />

            
            
            
          </Routes>

          <Footer />
        </BrowserRouter>
      </IntlProvider>
    </div>
  );
}

export default App;
