import { FormattedMessage } from 'react-intl'
import whatWeDo1 from '../../../../../assets/WhatWeDo/whatWeDo1.svg'
import whatWeDo2 from '../../../../../assets/WhatWeDo/whatWeDo2.svg'
import whatWeDo3 from '../../../../../assets/WhatWeDo/whatWeDo3.svg'
import whatWeDo4 from '../../../../../assets/WhatWeDo/whatWeDo4.svg'
import whatWeDo5 from '../../../../../assets/WhatWeDo/whatWeDo5.svg'
import whatWeDo6 from '../../../../../assets/WhatWeDo/whatWeDo6.svg'

export const whatWeDoData = [
    {
        id: 1,
        to:'/',
        icon: whatWeDo1,
        title: <FormattedMessage id="whatWeDoData__1" defaultMessage={"Commercial Construction"} />,
    },

    {
        id: 2,
        to:'/',
        icon: whatWeDo2,
        title: <FormattedMessage id="whatWeDoData__2" defaultMessage={"Custom Project Constriction"} />,
    },

    {
        id: 3,
        to:'/',
        icon: whatWeDo3,
        title: <FormattedMessage id="whatWeDoData__3" defaultMessage={"Residential Construction"} />,
    },

    {
        id: 4,
        to:'/',
        icon: whatWeDo4,
        title: <FormattedMessage id="whatWeDoData__4" defaultMessage={"Project Planning & Design"} />,
    },

    {
        id: 5,
        to:'/',
        icon: whatWeDo5,
        title: <FormattedMessage id="whatWeDoData__5" defaultMessage={"Construction Project Management"} />,
    },

    {
        id: 6,
        to:'/',
        icon: whatWeDo6,
        title: <FormattedMessage id="whatWeDoData__6" defaultMessage={"Renovation Projects"} />,
    }
]
