import React, { useEffect } from "react";
import "./JustConstruct.scss";
import AOS from 'aos';
import "aos/dist/aos.css";
import { FormattedMessage } from "react-intl";

const JustConstruct = () => {
    useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="JustConstruct">
      <p className="with72" data-aos="fade-right">
      <FormattedMessage id="JustConstruct__1" defaultMessage={"We don't just construct buildings. We craft landmarks that stand the test of time."} />
      </p>

      <div className="bigMan" data-aos="fade-left"></div>
    </div>
  );
};

export default JustConstruct;
