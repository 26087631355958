import React from 'react'
import './OurProjectsCard.scss'
import { FaLocationDot } from 'react-icons/fa6'

const OurProjectsCard = ({
    projectsBg,
    projectsName,
    projectsLocation
}) => {
  return (
    <div className='OurProjectsCard'
        style={{
            backgroundImage: `url(${projectsBg})`
        }}
    >
        <div className="OurProjectsCard__inside">
            <p className="with27">
            {projectsName}
            </p>

            <p className="with18">
            <FaLocationDot />
            {projectsLocation}
            </p>
        </div>
    </div>
  )
}

export default OurProjectsCard