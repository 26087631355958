import React, { useEffect, useState } from "react";
import "./News.scss";
import axios from "axios";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdOutlineSort,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { FaInstagram } from "react-icons/fa6";
import ReactPaginate from "react-paginate";

const News = () => {
  const intl = useIntl();
  const [latest, setLatest] = useState(false);
  const [dropDown, setDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All categories");
  const [pageNumber, setPageNumber] = useState(0);
  const projectsPerPage = 8;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setSearchQuery(urlParams.get("search") || "");
    setSelectedMonth(urlParams.get("month") || "");
    setSelectedYear(urlParams.get("year") || "");
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}News.json`)
      .then((res) => {
        setPageData(res.data);
        const allCategories = res.data.reduce((acc, curr) => {
          curr.categoriesNews.forEach((category) => {
            if (!acc.includes(category)) {
              acc.push(category);
            }
          });
          return acc;
        }, []);
        setCategories(["All categories", ...allCategories]);
      })
      .catch((error) => {
        console.error("News: ", error);
      });
  }, []);

  useEffect(() => {
    const filtered = pageData.filter((item) => {
      // Filter by search query
      const matchSearchQuery = item.newsDsc
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      // Filter by selected month
      const matchSelectedMonth =
        selectedMonth === "" ||
        item.monthNews.toLowerCase() === selectedMonth.toLowerCase();
      // Filter by selected year
      const matchSelectedYear =
        selectedYear === "" || item.yearNews === selectedYear;
      return matchSearchQuery && matchSelectedMonth && matchSelectedYear;
    });
    setFilteredData(filtered);

    // Update URL with search query, month, and year if they are not empty
    const params = new URLSearchParams();
    if (searchQuery.trim() !== "") {
      params.append("search", searchQuery);
    }
    if (selectedMonth.trim() !== "") {
      params.append("month", selectedMonth);
    }
    if (selectedYear.trim() !== "") {
      params.append("year", selectedYear);
    }
    const queryString = params.toString();
    window.history.pushState({}, "", `?${queryString}`);
  }, [searchQuery, selectedMonth, selectedYear, pageData]);

  const handleDropDown = () => {
    setDropdown(!dropDown);
  };

  const handleLatest = () => {
    setLatest(true);
  };

  const handleNewest = () => {
    setLatest(false);
  };

  const handleCategoryClick = (category) => {
    const filtered =
      category === "All categories"
        ? pageData
        : pageData.filter((item) => item.categoriesNews.includes(category));
    setFilteredData(filtered);
    setActiveCategory(category === "All categories" ? category : category);
  };

  const sortedPageData = [...filteredData];
  if (latest) {
    sortedPageData.sort((a, b) => b.id - a.id);
  }

  // const lastIndex = pageNumber * projectsPerPage + projectsPerPage;
  // const currentProjects = pageData.slice(
  //   pageNumber * projectsPerPage,
  //   lastIndex
  // );

  const pageCount = Math.ceil(pageData.length / projectsPerPage); // Calculate total number of pages

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected); // Update current page number
  };

  return (
    <div className="News">
      <div className="News__banner">
        <div className="News__banner__filter"></div>

        <div className="with62">
          <FormattedMessage id="News__1" defaultMessage={"Read latest news"} />
        </div>
      </div>

      <div className="News__2">
        <p className="with40">
          <FormattedMessage id="News__2" defaultMessage={"BuildKos news"} />
        </p>

        <div
          className={`latestNewest ${dropDown ? "remove100vmax" : ""}`}
          onClick={handleDropDown}
        >
          <div className={`WeSee`}>
            <MdOutlineSort />
            <span>
              {latest ? (
                <>
                  Latest
                  <FormattedMessage id="News__3" defaultMessage={"Latest"} />
                </>
              ) : (
                <>
                  <FormattedMessage id="News__4" defaultMessage={"Newest"} />
                </>
              )}
            </span>
            <MdKeyboardArrowDown
              className={`arrowSvg ${dropDown ? "rotateArrow" : ""}`}
            />
          </div>

          <div className={`FullMenu  ${dropDown ? "openFull" : ""}`}>
            <div
              className={`WeSee ${latest ? "active" : ""}`}
              onClick={handleLatest}
            >
              <MdOutlineSort />
              <span>
                <FormattedMessage id="News__3" defaultMessage={"Latest"} />
              </span>
            </div>

            <div
              className={`WeSee ${!latest ? "active" : ""}`}
              onClick={handleNewest}
            >
              <MdOutlineSort className="newestIcon" />
              <span>
                <FormattedMessage id="News__4" defaultMessage={"Newest"} />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="News__3">
        <div className="News__3__left">
          <div className="inputWithFull">
            <span className="iconInput"></span>
            <input
              type="search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={intl.formatMessage({ id: 'News__22', defaultMessage: 'Search news' })}

            />
          </div>

          <div className="Categories">
            <p className="with22">
              <FormattedMessage id="News__5" defaultMessage={"Categories"} />
            </p>

            <div className="categories__group">
              {categories.map((category, index) => (
                <p
                  key={index}
                  className={
                    category === activeCategory ? "with17BgActive" : "with17Bg"
                  }
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </p>
              ))}
            </div>
          </div>

          <div className="yearAndMonth">
            <div className="divWithInput">
              <label htmlFor="country">
                <FormattedMessage id="News__6" defaultMessage={"Month"} />
              </label>

              <div className="fullInput">
                <select
                  name=""
                  id=""
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  <option value="">
                    <FormattedMessage
                      id="ContactUs__13"
                      defaultMessage={"Select"}
                    />
                  </option>
                  <option value="January">
                    <FormattedMessage id="News__7" defaultMessage={"January"} />
                  </option>
                  <option value="February">
                    <FormattedMessage
                      id="News__8"
                      defaultMessage={"February"}
                    />
                  </option>
                  <option value="March">
                    <FormattedMessage id="News__9" defaultMessage={"March"} />
                  </option>
                  <option value="April">
                    <FormattedMessage id="News__10" defaultMessage={"April"} />
                  </option>
                  <option value="May">
                    <FormattedMessage id="News__11" defaultMessage={"May"} />
                  </option>
                  <option value="June">
                    <FormattedMessage id="News__12" defaultMessage={"June"} />
                  </option>
                  <option value="July">
                    <FormattedMessage id="News__13" defaultMessage={"July"} />
                  </option>
                  <option value="August">
                    <FormattedMessage id="News__14" defaultMessage={"August"} />
                  </option>
                  <option value="September">
                    <FormattedMessage
                      id="News__15"
                      defaultMessage={"September"}
                    />
                  </option>
                  <option value="October">
                    <FormattedMessage
                      id="News__16"
                      defaultMessage={"October"}
                    />
                  </option>
                  <option value="November">
                    <FormattedMessage
                      id="News__17"
                      defaultMessage={"November"}
                    />
                  </option>
                  <option value="December">
                    <FormattedMessage
                      id="News__18"
                      defaultMessage={"December"}
                    />
                  </option>
                </select>
              </div>
            </div>

            <div className="divWithInput">
              <label htmlFor="country">
                <FormattedMessage id="News__19" defaultMessage={"Year"} />
              </label>

              <div className="fullInput">
                <select
                  name=""
                  id=""
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option value="">
                    <FormattedMessage
                      id="ContactUs__13"
                      defaultMessage={"Select"}
                    />
                  </option>
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                </select>
              </div>
            </div>
          </div>

          <div className="followUs">
            <p className="with22">
              <FormattedMessage
                id="News__20"
                defaultMessage={"Follow us on social media"}
              />
            </p>

            <div className="insideFollow">
              <div className="followUsCard followUsCard1">
                <div className="instagramIcon">
                  <FaInstagram />
                </div>
              </div>

              <div className="followUsCard followUsCard2">
                <div className="instagramIcon">
                  <FaInstagram />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="News__3__right">
          <div className="inside__3__right">
            {filteredData
              .slice(
                pageNumber * projectsPerPage,
                (pageNumber + 1) * projectsPerPage
              )
              .map((item) => (
                <div className="newsCard" key={item.id}>
                  <div
                    className="top"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_NEWS_IMAGE}${item.newsImage})`,
                    }}
                  ></div>
                  <div className="bottom">
                  <p className="with15">
                    {item.dayNews}&nbsp;
                    <FormattedMessage
                      id={`newsMonth__${item.id}`}
                      defaultMessage={item.monthNews}
                    />
                    &nbsp;{item.yearNews}
                  </p>
                  <p className="with24">
                    <FormattedMessage
                      id={`newsDSC__${item.id}`}
                      defaultMessage={item.newsDsc}
                    />
                  </p>
                    <Link className="with16Color">
                      <FormattedMessage
                        id="News__21"
                        defaultMessage={"Read more"}
                      />
                    </Link>
                  </div>
                </div>
              ))}
          </div>

          <ReactPaginate
            previousLabel={<MdKeyboardArrowLeft />}
            nextLabel={<MdOutlineKeyboardArrowRight />}
            breakLabel={"..."}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </div>
      </div>
    </div>
  );
};

export default News;
