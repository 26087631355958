import React from 'react'
import './ProjectsMenuMobile.scss'
import { projectsData } from './projects'
import { Link } from 'react-router-dom'

const ProjectsMenuMobile = () => {
  return (
    <div className='CoursesMenuMobile'>

        <div className='itemMobile'>
            {projectsData.map((props) => {
                return(
                    <Link to={props.to} className='menu-main-link'>
                        <div className="main projectMain">
                            <div className="icon-container">
                                <div className="icon projectIcon" style={{content: `url(${props.icon})`}}></div>
                            </div>
                            <div className="block">
                                <h6>{props.title}</h6>
                            </div>
                        </div>
                    </Link>
                )
            })}     
        </div>
    </div>
  )
}

export default ProjectsMenuMobile