import React, { useEffect } from "react";
import "./YearsOfExperience.scss";
import AOS from "aos";
import "aos/dist/aos.css";
const YearsOfExperience = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="YearsOfExperience">
      <div className="YearsOfExperience__top">
        <p className="with50" data-aos="flip-up">
          We specialize in delivering innovative ideas and solutions to create
          inspiring and aesthetically pleasing projects.
        </p>
      </div>

      <div className="YearsOfExperience__bottom">
        <div className="YearsOfExperience__left">
          <div className="yearsBorder" data-aos="fade-right">
            <p className="with155" data-aos="flip-up">15</p>
            <p className="with26" data-aos="flip-up">years of experience</p>
          </div>

          <div className="numberPlus">
            <div className="firstOne" data-aos="fade-right">
              <p className="with70">200+</p>

              <p className="with17">projects completed</p>
            </div>

            <div className="firstOne" data-aos="fade-left">
              <p className="with70">30+</p>

              <p className="with17">business partners</p>
            </div>
          </div>
        </div>

        <div className="YearsOfExperience__right" data-aos="fade-left">
          <div className="YearsOfExperience__right__filter"></div>
        </div>
      </div>
    </div>
  );
};

export default YearsOfExperience;
