import project1 from '../../../../../assets/ProjectsNav/project1.webp'
import project2 from '../../../../../assets/ProjectsNav/project2.webp'
import project3 from '../../../../../assets/ProjectsNav/project3.webp'
import project4 from '../../../../../assets/ProjectsNav/project4.webp'

export const projectsData = [
    {
        id: 1,
        to:'/',
        icon: project1,
        title: "Prestige Residence"
    },
    {
        id: 2,
        to:'/',
        icon: project2,
        title: "The Green Project"
    },
    {
        id: 3,
        to:'/',
        icon: project3,
        title: "Buildkos Group 1"
    },
    {
        id: 4,
        to:'/',
        icon: project4,
        title: "Royal Hill"
    },

    {
        id: 5,
        to:'/',
        icon: project1,
        title: "Prestige Residence"
    },
    {
        id: 6,
        to:'/',
        icon: project2,
        title: "The Green Project"
    },
    {
        id: 7,
        to:'/',
        icon: project3,
        title: "Buildkos Group 1"
    },
    {
        id: 8,
        to:'/',
        icon: project4,
        title: "Royal Hill"
    },

    {
        id: 9,
        to:'/',
        icon: project1,
        title: "Prestige Residence"
    }
]
