import React, { useEffect } from "react";
import "./BuildingYourFeature.scss";
import YearsOfExperience from "../YearsOfExperience/YearsOfExperience";
import AOS from "aos";
import "aos/dist/aos.css";
import { FormattedMessage } from "react-intl";

const BuildingYourFeature = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="buildAndYear">
      <div className="BuildingYourFeature">
        <div
          className="BuildingYourFeature__filter"
          data-aos="fade-right"
        ></div>

        <div className="BuildingYourFeature__inside">
          <p className="with62" data-aos="fade-right">
            <FormattedMessage
              id="BuildingYourFeature__1"
              defaultMessage={"Building your future today"}
            />
          </p>

          <p className="with20" data-aos="fade-right">
            <FormattedMessage
              id="BuildingYourFeature__2"
              defaultMessage={
                "At BuildKos, we're more than just a construction company; we're architects of dreams. With decades of experience, state-of-the-art technology, and a commitment to sustainability, we transform blueprints into reality. BuildKos is the trusted partner for all your construction needs."
              }
            />
          </p>

          <div className="BuildingYourFeature___line"></div>

          <p className="with37" data-aos="fade-right">
            A.Halimi
          </p>

          <div className="ceo">
            <p className="with18" data-aos="fade-right">
              Alban Halimi
            </p>

            <p className="with14" data-aos="fade-right">
              CEO, BuildKos
            </p>
          </div>
        </div>
      </div>

      <YearsOfExperience />
    </div>
  );
};

export default BuildingYourFeature;
