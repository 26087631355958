import React, { useEffect } from "react";
import "./ContactUs.scss";
import Navbar from "../../component/shared/Navbar/Navbar";
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const intl = useIntl();

  return (
    <div className="ContactUs">
      <Navbar />
      <div className="ContactUs__1">
        <div className="ContactUs__1__filter"></div>

        <div className="ContactUs__1__content">
          <p className="with63" data-aos="fade-right">
            <FormattedMessage id="ContactUs__1" defaultMessage={"Contact us"} />
          </p>
        </div>
      </div>

      <div className="ContactUs__2">
        <div className="ContactUs__2__left" data-aos="fade-right">
          <div className="top">
            <p className="with35">
              <FormattedMessage
                id="ContactUs__2"
                defaultMessage={"Send us an inquiry"}
              />
            </p>

            <p className="with16">
              <FormattedMessage
                id="ContactUs__3"
                defaultMessage={
                  "Complete the form and provide us with your information and we will contact you as soon as possible"
                }
              />
            </p>
          </div>

          <div className="bottom">
            <div className="divWithInput">
              <label htmlFor="fullName">
                <FormattedMessage
                  id="ContactUs__4"
                  defaultMessage={"Full name"}
                />
                *
              </label>

              <div className="fullInput">
                <input
                  type="text"
                  placeholder={intl.formatMessage({ id: 'ContactUs__5', defaultMessage: 'Enter your full name' })}
                />
                <span className="iconInput iconInput1"></span>
              </div>
            </div>

            <div className="divWithInput">
              <label htmlFor="email">Email*</label>

              <div className="fullInput">
                <input
                  type="email"
                  placeholder={intl.formatMessage({ id: 'ContactUs__6', defaultMessage: 'Enter your email address' })}
                />
                <span className="iconInput iconInput2"></span>
              </div>
            </div>

            <div className="divWithInput">
              <label htmlFor="phoneNumber">
                <FormattedMessage
                  id="ContactUs__7"
                  defaultMessage={"Phone Number"}
                />
                *
              </label>

              <div className="fullInput">
                <input
                  type="number"
                  placeholder={intl.formatMessage({ id: 'ContactUs__8', defaultMessage: 'Enter your phone number' })}
                />
                <span className="iconInput iconInput3"></span>
              </div>
            </div>

            <div className="divWithInput">
              <label htmlFor="organization">
                <FormattedMessage
                  id="ContactUs__9"
                  defaultMessage={"Organization/Company"}
                />
              </label>

              <div className="fullInput">
                <input
                  type="text"
                  placeholder={intl.formatMessage({ id: 'ContactUs__10', defaultMessage: 'Enter your organization name' })}
                />
                <span className="iconInput iconInput4"></span>
              </div>
            </div>

            <div className="divWithInput">
              <label htmlFor="country">
                <FormattedMessage
                  id="ContactUs__11"
                  defaultMessage={"Country"}
                />
                *
              </label>

              <div className="fullInput">
                <select name="" id="">
                  <option value="" disabled>
                    <FormattedMessage
                      id="ContactUs__13"
                      defaultMessage={"Select"}
                    />
                  </option>
                  <option value="Kosovo">Kosovo</option>
                  <option value="Albanian">Albanian</option>
                  <option value="Canada">Canada</option>
                  <option value="Brazil">Brazil</option>
                  <option value="Australia">Australia</option>
                  <option value="Japan">Japan</option>
                  <option value="Germany">Germany</option>
                  <option value="Russia">Russia</option>
                  <option value="Egypt">Egypt</option>
                  <option value="South Africa">South Africa</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Argentina">Argentina</option>
                </select>
              </div>
            </div>

            <div className="divWithInput">
              <label htmlFor="topic">
                <FormattedMessage id="ContactUs__12" defaultMessage={"Topic"} />
                *
              </label>

              <div className="fullInput">
                <select name="" id="">
                  <option value="" disabled>
                    <FormattedMessage
                      id="ContactUs__13"
                      defaultMessage={"Select"}
                    />
                  </option>
                  <option value="PrestigeResidence">
                    <FormattedMessage
                      id="ContactUs__14"
                      defaultMessage={"Prestige Residence"}
                    />
                  </option>
                  <option value="TheGreenProject">
                    <FormattedMessage
                      id="ContactUs__15"
                      defaultMessage={"The Green Project"}
                    />
                  </option>
                  <option value="BuildKosGroup">BuildKos Group</option>
                  <option value="RoyalHill">
                    <FormattedMessage
                      id="ContactUs__16"
                      defaultMessage={"Royal Hill"}
                    />
                  </option>
                </select>
              </div>
            </div>

            <div className="divWithInput">
              <label htmlFor="message">
                <FormattedMessage
                  id="ContactUs__17"
                  defaultMessage={"Message"}
                />
              </label>

              <textarea 
                  placeholder={intl.formatMessage({ id: 'ContactUs__21', defaultMessage: 'Tell us more' })}

              ></textarea>
            </div>

            <Link className="dcenter">
              <FormattedMessage id="ContactUs__18" defaultMessage={"Submit"} />
            </Link>
          </div>
        </div>

        <div className="ContactUs__2__right" data-aos="fade-left">
          <div className="c2">
            <p className="with22">
              <FormattedMessage
                id="ContactUs__19"
                defaultMessage={"Contact us directly via"}
              />
            </p>

            <span>
              <FormattedMessage id="ContactUs__20" defaultMessage={"Locals"} />
            </span>
          </div>

          <div className="makeGroupCards">
            <div className="CallCard">
              <div className="left dcenter">
                <div className="icon icon1"></div>
              </div>

              <div className="right">
                <span>Email</span>

                <p>info@buildkos.com</p>
              </div>
            </div>

            <div className="CallCard">
              <div className="left dcenter">
                <div className="icon"></div>
              </div>

              <div className="right">
                <span>
                  <FormattedMessage id="Footer__20" defaultMessage={"Phone"} />
                </span>

                <p>+ 123 456 789 00</p>
              </div>
            </div>

            <div className="CallCard">
              <div className="left dcenter">
                <div className="icon icon3"></div>
              </div>

              <div className="right">
                <span>
                  <FormattedMessage
                    id="Footer__21"
                    defaultMessage={"Headquarters:"}
                  />
                </span>

                <p>"Ali Kelmendi", No. 10, Prishtina, Kosovo</p>
              </div>
            </div>
          </div>

          <div className="forIframe">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19740.052106725947!2d21.145615760733094!3d42.65338050845788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549ef6fa27ace5%3A0x5b417ac60d73c18d!2sKomuna%20e%20Prishtin%C3%ABs!5e0!3m2!1sen!2s!4v1714869882704!5m2!1sen!2s"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="iframeForMap"
                title="myIframe"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
