import React, { useEffect, useState } from "react";
import "./OurServices.scss";
import { Link } from "react-router-dom";
import ServicesCard from "../../shared/ServicesCard/ServicesCard";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { FormattedMessage } from "react-intl";

const OurServices = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}OurServices.json`)
      .then((res) => {
        setPageData(res.data);
      })
      .catch((error) => {
        console.error("OurServices : ", error);
      });
  }, []);

  return (
    <div className="OurServices">
      <div className="OurServices__top">
        <div className="OurServices__left">
          <p className="with62" data-aos="fade-right">
            <FormattedMessage
              id="OurServices__1"
              defaultMessage={"Our services"}
            />
          </p>

          <p className="with20" data-aos="fade-left">
            <FormattedMessage
              id="OurServices__2"
              defaultMessage={
                "BuildKos offers a full spectrum of construction services, from residential renovations to large-scale commercial projects. Our expertise includes custom home building, eco-friendly constructions, modern office spaces, and much more."
              }
            />
          </p>
        </div>

        <Link className="dcenter btnOnHover" data-aos="fade-right">
          <FormattedMessage id="OurServices__3" defaultMessage={"What we do"} />
        </Link>
      </div>

      <div className="OurServices__bottom" data-aos="fade-left">
        {pageData.slice(0, 3).map((item) => (
          <ServicesCard
            key={item.idServices}
            servicesTitle={
              <FormattedMessage
                id={`OurServices__bottom__${item.idServices}`}
                defaultMessage={item.titleServices}
              />
            }
            servicesImage={`${process.env.REACT_APP_OURSERVICES_IMAGE}${item.bgServices}`}
            servicesIcon={`${process.env.REACT_APP_OURSERVICES_IMAGE}${item.iconServices}`}
          />
        ))}
        <div className="OurServices__InServiceCard">
          <p className="with36">
            <FormattedMessage
              id="OurServices__4"
              defaultMessage={"Do you have a project in mind?"}
            />
          </p>

          <Link>
            <FormattedMessage
              id="OurServices__5"
              defaultMessage={"Get in touch"}
            />
            <BsArrowRight />
          </Link>
        </div>
        <span className="onlyToFillSpace"></span>
        {pageData.slice(3, 6).map((item) => (
          <ServicesCard
            key={item.idServices}
            servicesTitle={item.titleServices}
            servicesImage={`${process.env.REACT_APP_OURSERVICES_IMAGE}${item.bgServices}`}
            servicesIcon={`${process.env.REACT_APP_OURSERVICES_IMAGE}${item.iconServices}`}
          />
        ))}
      </div>
    </div>
  );
};

export default OurServices;
