import React, { useEffect, useState } from "react";
import "./Projects.scss";
import axios from "axios";
import OurProjectsCard from "../../component/shared/OurProjectsCard/OurProjectsCard";
import ReactPaginate from "react-paginate";
import {
  MdKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Projects = () => {
  const [pageData, setPageData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const projectsPerPage = 9;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}OurProjects.json`)
      .then((res) => {
        setPageData(res.data);
      })
      .catch((error) => {
        console.error("OurProjects : ", error);
      });
  }, []);

  const lastIndex = pageNumber * projectsPerPage + projectsPerPage;
  const currentProjects = pageData.slice(
    pageNumber * projectsPerPage,
    lastIndex
  );

  const pageCount = Math.ceil(pageData.length / projectsPerPage); // Calculate total number of pages

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected); // Update current page number
  };

  return (
    <div className="Projects">
      <div className="Projects__top">
        <div className="Projects__top__filter"></div>
        <p className="with80">
          
          <FormattedMessage
                id="Projects__1"
                defaultMessage={"Projects"}
              />
          </p>
      </div>

      <div className="Projects__1">
        <p className="with42">
          
          <FormattedMessage
            id="Projects__2"
            defaultMessage={"The quality you dream of"}
          />  
        </p>

        <p className="with18">
          

          <FormattedMessage
            id="Projects__3"
            defaultMessage={"Our projects are a testament to our commitment to excellence, innovation, and sustainable construction. From apartments to comfortable homes, each project tells a story of planning and precise execution."}
          />  
        </p>
      </div>

      <div className="Projects__2">
        {currentProjects.map((item) => (
          <Link
            key={item.id}
            to={{
              pathname: `/projects/${item.id}/${item.nameProject.replace(
                / /g,
                "_"
              )}`,
              state: { projectsDataTo: item },
            }}
          >
            <OurProjectsCard
              key={item.id}
              projectsBg={`${process.env.REACT_APP_OURPROJECTS_IMAGE}${item.imageProject}`}
              projectsLocation={item.locationProject}
              projectsName={item.nameProject}
            />
          </Link>
        ))}
      </div>

      <div className="Projects__3">
        <ReactPaginate
          previousLabel={<MdKeyboardArrowLeft />}
          nextLabel={<MdOutlineKeyboardArrowRight />}
          breakLabel={"..."}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </div>
    </div>
  );
};

export default Projects;
