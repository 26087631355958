import React, { useEffect, useRef, useState } from "react";
import "./OurProjects.scss";
import { Link } from "react-router-dom";
import OurProjectsCard from "../../shared/OurProjectsCard/OurProjectsCard";
import Carousel from "react-elastic-carousel";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { FormattedMessage } from "react-intl";
const breakPoints = [
  { width: 1, itemsToShow: 1, pagination: true },
  { width: 550, itemsToShow: 2, itemsToScroll: 1 },
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 3, itemsToScroll: 1 },
];

const OurProjects = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}OurProjects.json`)
      .then((res) => {
        setPageData(res.data);
      })
      .catch((error) => {
        console.error("OurProjects : ", error);
      });
  }, []);

  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };
  return (
    <div className="OurProjects">
      <div className="OurProjects__top">
        <p className="with18Yellow" data-aos="fade-right">
          <FormattedMessage
            id="OurProjects__1"
            defaultMessage={"Our Work Speaks for Itself"}
          />
        </p>
        <p className="with62" data-aos="fade-left">
          <FormattedMessage
            id="OurProjects__2"
            defaultMessage={"Our projects"}
          />
        </p>

        <p className="with20" data-aos="fade-right">
          <FormattedMessage
            id="OurProjects__3"
            defaultMessage={
              "Explore our diverse portfolio showcasing our versatility and expertise in construction. Get inspired by our past projects and envision your own."
            }
          />
        </p>
      </div>

      <div className="OurProjects__mid" data-aos="flip-up">
        <Carousel
          className="featured-carousel"
          showArrows={true}
          pagination={false}
          enableSwipe={true}
          breakPoints={breakPoints}
          ref={carouselRef}
          onPrevStart={onPrevStart}
          onNextStart={onNextStart}
          disableArrowsOnEnd={false}
        >
          {pageData.map((item) => (
            <Link
              key={item.id}
              to={{
                pathname: `/projects/${item.id}/${item.nameProject.replace(
                  / /g,
                  "_"
                )}`,
                state: { projectsDataTo: item },
              }}
            >
              <OurProjectsCard
                projectsBg={`${process.env.REACT_APP_OURPROJECTS_IMAGE}${item.imageProject}`}
                projectsLocation={item.locationProject}
                projectsName={item.nameProject}
              />
            </Link>
          ))}
        </Carousel>
      </div>

      <div className="OurProjects__bottom">
        <Link className="dcenter btnOnHover">
          <FormattedMessage
            id="OurProjects__4"
            defaultMessage={"View all projects"}
          />
        </Link>
      </div>
    </div>
  );
};

export default OurProjects;
