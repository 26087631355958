import React from 'react'
import './WhatWeDoMobile.scss'
import { whatWeDoData } from './what.js'
import { Link } from 'react-router-dom'

const WhatWeDoMobile = () => {
  return (
    <div className='CoursesMenuMobile'>

        <div className='itemMobile'>
            {whatWeDoData.map((props) => {
                return(
                    <Link to={props.to} className='menu-main-link'>
                        <div className="bg"></div>
                        <div className="main">
                            <div className="icon-container">
                                <div className="icon" style={{content: `url(${props.icon})`}}></div>
                            </div>
                            <div className="block">
                                <h6>{props.title}</h6>
                            </div>
                        </div>
                    </Link>
                )
            })}     
        </div>
    </div>
  )
}

export default WhatWeDoMobile