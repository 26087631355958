import React, { useEffect } from 'react'
import './TheFundation.scss'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import "aos/dist/aos.css";
import { FormattedMessage } from 'react-intl';
const TheFundation = () => {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className='TheFundation' >
      <div className="TheFundation__icon"></div>
      <p className="with62" data-aos="fade-right">
      <FormattedMessage id="TheFundation__1" defaultMessage={"The foundation of BuildKos"} />
      </p>

      <p className="with20" data-aos="fade-left">
      <FormattedMessage id="TheFundation__2" defaultMessage={"Founded on the principles of integrity, innovation, and excellence, BuildKos has grown from a family-run business to a leading construction firm. Our journey is marked by groundbreaking projects and satisfied clients. Meet the team behind our success and learn about our mission to redefine construction standards."} />
      </p>

      <Link className='dcenter btnOnHover' data-aos="fade-right">
      <FormattedMessage id="TheFundation__3" defaultMessage={"About us"} />
      </Link>
        

    </div>
  )
}

export default TheFundation