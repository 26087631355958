import React, { useEffect, useRef, useState } from 'react'
import './ProjectsDetails.scss'
import Navbar from '../../component/shared/Navbar/Navbar'
import { FaLocationDot } from 'react-icons/fa6'
import axios from 'axios'
import Carousel from "react-elastic-carousel";
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: true },
    { width: 550, itemsToShow: 2, itemsToScroll: 1 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 1 },
];

const ProjectsDetails = () => {
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_ROUTE}OurProjects.json`)
            .then((res) => {
                setPageData(res.data);
            })
            .catch((error) => {
                console.error("OurProjects : ", error);
            });
    }, []);

    const carouselRef = useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo("single-slide".length);
        }
    };



    const { id } = useParams();

    const item =
    pageData.find((item) => item.id === Number(id));


    return (
        <div className='ProjectsDetails'>
            <Navbar />

            <div className="ProjectsDetails__baner"
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_OURPROJECTS_IMAGE}${item && item.imageProject})`
                }}
            >
                <div className="OurProjectsCard__inside">
                    <p className="with27">
                        {
                            item && item.nameProject
                        }
                    </p>

                    <p className="with18">
                        <FaLocationDot />
                        {
                            item && item.locationProject
                        }
                    </p>
                </div>
            </div>

            <div className="ProjectsDetails__2">
                <div className="left">
                    <div className="titleAndText">
                        <p className="with62">
                        {
                            item && item.nameProject
                        }
                        </p>

                        <p className="with18">
                            {
                                item && <FormattedMessage
                                id={`projectJson__${item.id}`}
                                defaultMessage={item.projectDsc}
                              />
                            }
                        </p>
                    </div>

                    <div className="locationAndContry">
                        <p className="with17">
                            <FormattedMessage
                                id="ProjectsDetails__1"
                                defaultMessage={"Location"}
                            />
                        </p>

                        <p className="with18">
                            {
                                item && item.locationProject
                            }
                            , {
                                item && item.country
                            }
                        </p>
                    </div>

                    <div className="numberOfUnits">
                        <p className="with17">
                            <FormattedMessage
                                id="ProjectsDetails__2"
                                defaultMessage={"Number of Units and Configuration"}
                            />
                        </p>

                        <p className="with18">
                            {
                                item && <FormattedMessage
                                id={`projectJsonNoucDSC__${item.id}`}
                                defaultMessage={item.NumberofUnitsandConfigurationDsc}
                              />
                            }
                        </p>
                            
                            {
                                item
                                && 
                                item.NumberofUnitsandConfiguration.map((itemInside) => (
                                    <div className='meter'>
                                        <p className="with19">
                                            {itemInside.number}
                                        </p>
                                        <p className="with19Regular">
                                            <FormattedMessage
                                                id={`itemInside_dsc_${itemInside.id}`}
                                                defaultMessage={itemInside.dsc}
                                            />&nbsp;
                                            {
                                                itemInside.numberInSquare
                                            }
                                        </p>
                                    </div>
                                ))
                            }
                    </div>
                </div>

                <div className="right">
                    <div className="rightInside"
                        style={{
                            backgroundImage: `url(${process.env.REACT_APP_OURPROJECTS_IMAGE}${item && item.bluePrintImage})`
                        }}
                    ></div>
                </div>
            </div>

            <div className="ProjectsDetails__3">
                <Carousel
                    className="featured-carousel"
                    showArrows={true}
                    pagination={false}
                    enableSwipe={true}
                    breakPoints={breakPoints}
                    ref={carouselRef}
                    onPrevStart={onPrevStart}
                    onNextStart={onNextStart}
                    disableArrowsOnEnd={false}
                >
                    {pageData.map((item) => (
                        <div
                            key={item.id}
                            className='insideDetails'
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_OURPROJECTS_IMAGE}${item.imageProject})`
                            }}
                        >
                        </div>
                    ))}
                </Carousel>
            </div>

            <div className="ProjectsDetails__4">
                <p className="with47">
                
                <FormattedMessage
                    id="ProjectsDetails__3"
                    defaultMessage={"Amenities and Features"}
                />
                
                </p>

                <div className="ProjectsDetails__cards">
                    <div className="ProjectsDetailsCard">
                        <div className="iconDetails iconDetails1"></div>

                        <p className="with24">
                        
                        <FormattedMessage
                            id="ProjectsDetails__4"
                            defaultMessage={"Green Spaces"}
                        />
                        </p>

                        <p className="with16">
                        
                        <FormattedMessage
                            id="ProjectsDetails__5"
                            defaultMessage={"Beautifully landscaped gardens and walking paths to encourage outdoor activities and community interaction."}
                        />
                        </p>
                    </div>

                    <div className="ProjectsDetailsCard">
                        <div className="iconDetails iconDetails2"></div>

                        <p className="with24">
                        
                        <FormattedMessage
                            id="ProjectsDetails__6"
                            defaultMessage={"Underground parking"}
                        />
                        </p>

                        <p className="with16">
                        
                        <FormattedMessage
                            id="ProjectsDetails__7"
                            defaultMessage={"A secure underground parking with provisions for electric vehicle charging."}
                        />
                        </p>
                    </div>

                    <div className="ProjectsDetailsCard">
                        <div className="iconDetails iconDetails3"></div>

                        <p className="with24">
                        
                        <FormattedMessage
                            id="ProjectsDetails__8"
                            defaultMessage={"Community clubhouse"}
                        />
                        </p>

                        <p className="with16">
                        
                        <FormattedMessage
                            id="ProjectsDetails__9"
                            defaultMessage={"A community clubhouse located at the heart of the complex, equipped with a gym, versatile rooms for events, and a cozy café."}
                        />
                        </p>
                    </div>

                    <div className="ProjectsDetailsCard">
                        <div className="iconDetails iconDetails4"></div>

                        <p className="with24">
                        
                        <FormattedMessage
                            id="ProjectsDetails__10"
                            defaultMessage={"Child-friendly amenities"}
                        />
                        </p>

                        <p className="with16">
                        

                        <FormattedMessage
                            id="ProjectsDetails__11"
                            defaultMessage={"A dedicated children’s play area with modern and safe playground equipment, and an indoor game room for older children."}
                        />
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProjectsDetails