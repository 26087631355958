import React, { useEffect } from "react";
import "./GetInTouch.scss";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { FormattedMessage } from "react-intl";

const GetInTouch = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="GetInTouch">
      <div className="GetInTouch__top" data-aos="flip-up"></div>

      <div className="GetInTouch__bottom">
        <p className="with62" data-aos="fade-right">
          <FormattedMessage
            id="GetInTouch__1"
            defaultMessage={"Get in touch with us"}
          />
        </p>

        <p className="with20" data-aos="fade-left">
          <FormattedMessage
            id="GetInTouch__2"
            defaultMessage={
              "Ready to start your construction project? Contact BuildKos today. Our team is here to answer your questions, provide expert consultations, and bring your vision to life. Reach out to us through our contact form, email, or phone. Let's build something extraordinary together."
            }
          />
        </p>

        <Link to={'/contact-us'} className="dcenter btnOnHover">
          <FormattedMessage id="GetInTouch__3" defaultMessage={"Contact us"} />
        </Link>
      </div>
    </div>
  );
};

export default GetInTouch;
