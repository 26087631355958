import React, { useEffect, useState } from 'react'
import './navbar.scss'
import { Link } from 'react-router-dom'
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher'
import clsx from "clsx";

import { BsTriangleFill } from 'react-icons/bs'
import {MdKeyboardArrowDown} from 'react-icons/md'
import MobileNav from './Mobile/MobileNav';
import WhatWeDo2 from './DropDown/WhatWeDo/WhatWeDo2';
import ProjectsMenu from './DropDown/Projects/ProjectsMenu';
import { FormattedMessage } from 'react-intl';

const Navbar = (props) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
  const handleScroll = () => {
    const scrollHeight = window.scrollY;
    setIsSticky(scrollHeight > 25 ? true : false);
  };

  window.addEventListener("scroll", handleScroll);

  return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={clsx(`nav ${props.styles}`, isSticky ? "nav__sticky" : "")}>
        <div className="left_side">
            <Link to="/" className="nav_logo">
                <div className="logo_container"></div>
            </Link>
        </div>

        <div className="nav_links">
          <div className='nav-link about-link'>
            <Link to='/about-us' className='inner-nav-link'>
              <FormattedMessage
                id="Nav__1"
                defaultMessage={"About us"}
              />
            </Link>
          </div>

          <div className='nav-link courses-link'>
              <Link to='/' className='inner-nav-link'>
              <FormattedMessage
                id="Nav__2"
                defaultMessage={"What we do"}
              />
                <MdKeyboardArrowDown className='arrow1'/>
              </Link>
              <div className='megamenu-arrow'><BsTriangleFill /></div>
                <WhatWeDo2 /> 
          </div>


          <div className='nav-link courses-link'>
              <Link to='/projects' className='inner-nav-link'>
              <FormattedMessage
                id="Nav__3"
                defaultMessage={"Projects"}
              />
                <MdKeyboardArrowDown className='arrow1'/>
              </Link>
              <div className='megamenu-arrow'><BsTriangleFill /></div>
                <ProjectsMenu /> 
          </div>

          <div className='nav-link community-link'>
            <Link to='/news' className='inner-nav-link'>
              
              <FormattedMessage
                id="Nav__4"
                defaultMessage={"News"}
              />
            </Link>
          </div>

          <div className='nav-link Contact_us-link'>
            <Link to='/' className='inner-nav-link'>
              <FormattedMessage
                id="Nav__5"
                defaultMessage={"Careers"}
              />
            </Link>
          </div>
        </div>

        <div className="nav_auth">
          <div className="CallCard">
            <div className="left dcenter">
              <div className="icon"></div>
            </div>

            <div className="right">
              <span>
                <FormattedMessage
                  id="Nav__6"
                  defaultMessage={"Call us:"}
                />
              </span>

              <p>
              + 123 456 789 00
              </p>
            </div>
          </div>

          <Link to={"/contact-us"} className='apply_now dcenter btnOnHover'>
            
            <FormattedMessage
              id="Nav__7"
              defaultMessage={"Contact us"}
            />
          </Link>
          <ThemeSwitcher />
        </div>

        <MobileNav />
    </div>
  )
}

export default Navbar