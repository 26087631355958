import React from "react";
import "./ServicesCard.scss";
import { GoArrowRight } from "react-icons/go";

const ServicesCard = ({ servicesImage, servicesTitle, servicesIcon }) => {
  return (
    <div className="ServicesCard">
      <div className="ServicesCardFilter"></div>
      <div
        className="ServicesCardBgImage"
        style={{
          backgroundImage: `url(${servicesImage})`,
        }}
      ></div>

      <div className="inRightServices">
        <div
          className="serviceIcon"
          style={{
            content: `url(${servicesIcon})`,
          }}
        ></div>

        <p>{servicesTitle}</p>
      </div>

      <div className="inRightTop">
        <GoArrowRight />
      </div>
    </div>
  );
};

export default ServicesCard;
