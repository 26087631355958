import React, { useEffect, useState } from "react";
import "./HomeBanner.scss";
import { Link } from "react-router-dom";

import image1 from '../../../assets/Home/HomeBanner/home1Bg.webp';
import image2 from '../../../assets/Home/HomeBanner/home2Bg.webp';
import image2Dark from '../../../assets/Home/HomeBanner/home2BgDark.webp';
import { FormattedMessage } from "react-intl";

const HomeBanner = () => {
    const [showFilter, setShowFilter] = useState(true);
    const [isSlideFromLeft, setIsSlideFromLeft] = useState(false);
    const [currentImage, setCurrentImage] = useState(image1);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage(prevImage => prevImage === image1 ? image2 : image1);
            setShowFilter(prevShowFilter => !prevShowFilter);
            setIsSlideFromLeft(true);
        }, 5000);
        
        const handleTransitionEnd = () => {
            setIsSlideFromLeft(false);
        };
        
        const bannerElement = document.querySelector(".HomeBanner");
        bannerElement.addEventListener("transitionend", handleTransitionEnd);
        
        const body = document.body;
        const isDarkMode = body.classList.contains("dark-mode");
        setCurrentImage(isDarkMode ? image2Dark : image2);
        setShowFilter(true);
        
        return () => {
            clearInterval(interval);
            bannerElement.removeEventListener("transitionend", handleTransitionEnd);
        };
    }, []);

    return (
        <div className={`HomeBanner ${isSlideFromLeft ? "slide-from-left" : ""}`}
            style={{
                backgroundImage: `url(${currentImage})`
            }}
        >
            <div className={showFilter ? "HomeBanner__filter__hide" : "HomeBanner__filter"}></div>

            <div className="insiderHomeBanner">
                <p className="with100">
                    <FormattedMessage id="HomeBanner__1" defaultMessage={"Buildings that can be used by many generations"} />
                </p>

                <p className="with20">
                    <FormattedMessage id="HomeBanner__2" defaultMessage={"Welcome to "} />
                    <span>BuildKos</span>
                    <FormattedMessage id="HomeBanner__3" defaultMessage={", where every brick tells a story of innovation, precision, and dedication."} />
                </p>

                <Link className="btnOnHover" to="/">
                    <FormattedMessage id="HomeBanner__4" defaultMessage={"Explore Projects"} />
                    <span className="arrow45"></span>
                </Link>
            </div>
        </div>
    );
};

export default HomeBanner;
